import React, { useState } from "react";
import { animated, useSpring } from "react-spring";
import useMeasure from "react-use-measure";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import styled from "styled-components";
import { FaPlus, FaMinus } from "react-icons/fa";

interface Props {
  title: string;
  expandableText: string;
}

const ExpandableItem: React.FC<Props> = (props) => {
  const { title, expandableText } = props;

  const [open, setOpen] = useState<boolean>(false);
  const [hovered, setHovered] = useState<boolean>(false);

  const [expandableTextMeasureRef, expandableTextBounds] = useMeasure();
  const [titleMeasureRef, titleBounds] = useMeasure();

  const style = useSpring({
    height: open
      ? `${expandableTextBounds.height + titleBounds.height + 52}px`
      : `${titleBounds.height + 52}px`,
  });
  return (
    <ClickAwayListener onClickAway={() => setOpen(false)}>
      <Wrapper
        style={style}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
      >
        <Header onClick={() => setOpen(!open)}>
          <Title open={open}>
            <p ref={titleMeasureRef}>{title}</p>
          </Title>
          <Icon hovered={hovered}>
            <FaPlus
              style={{
                opacity: open ? 0 : 1,
              }}
            />
            <FaMinus
              style={{
                opacity: open ? 1 : 0,
                fill: "var(--color-orange)",
              }}
            />
          </Icon>
        </Header>
        <ExpandableText ref={expandableTextMeasureRef}>
          <p>{expandableText}</p>
        </ExpandableText>
      </Wrapper>
    </ClickAwayListener>
  );
};

export default ExpandableItem;

const Wrapper = styled(animated.div)`
  overflow: hidden;
  position: relative;
  width: 100%;
  padding-left: 27px;
  padding-right: 16px;
  background: #ffffff;
  box-shadow: var(--shadow);
  border-radius: 20px;
  margin-bottom: 32px;
`;

const Header = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
`;

const Icon = styled.div<{ hovered: boolean }>`
  position: relative;
  width: 41px;
  height: 41px;
  border-radius: 10px;
  background: ${(props) => (props.hovered ? `#f3f3f3` : `rgba(0,0,0,0)`)};
  transition: 0.2s;
  svg {
    position: absolute;
    width: 18px;
    height: 18px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: opacity 0.2s;
  }
`;

const Title = styled.div<{ open: boolean }>`
  p {
    text-transform: uppercase;
    line-height: 26px;
    margin: 26px 0;
  }
  font-weight: 600;

  transition: 0.2s;
  color: ${(props) => (props.open ? `var(--color-orange)` : `#000`)};
`;
const ExpandableText = styled.div`
  p {
    margin: 0;
    padding: 0 0 20px 0;
  }
`;
