import React from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import styled from "styled-components";
import Layout from "../components/layout";
import SEO from "../components/seo";
import { Button } from "../styles/ui.styles";
import ExpandableItem from "../components/expandableItem";
import IconMAN from "../images/svg/manuals.inline.svg";
import IconTUT from "../images/svg/tutorials.inline.svg";
import faqData from "../../content/faq/faq.json";

const Support: React.FC = () => {
  let faqCategories = faqData.mainEntity.map((faqEntry) => faqEntry.about);
  faqCategories = [...new Set(faqCategories)]; // remove duplicates from array

  return (
    <>
      <SEO title={"Support"} description={"Support"} isFaq={true} />
      <Layout allowTransparentHeader={true}>
        <ImgContainer>
          <StaticImage
            style={{ width: "100%", height: "100%" }}
            src={"../images/support.jpg"}
            alt={"Support image"}
          />
          <Overlay />
          <TitleWrapper>
            <h1>Do you need help?</h1>
          </TitleWrapper>
        </ImgContainer>
        <Wrapper>
          <h2>Select a topic</h2>
          <Topics>
            <div>
              <IconMAN />
              <h3>Manuals</h3>
              <p>
                The Fastrrr support area contains articles to help you get the most from your product.
              </p>
              <Link to={"/support/manuals"}>
                <BlackButton aria-label={"Manuals"}>LEARN MORE</BlackButton>
              </Link>
            </div>
            <div>
              <IconTUT />
              <h3>Tutorials</h3>
              <p>
                The Fastrrr tutorial area contains articles to help you get the most from your product.
              </p>
              <Link to={"/support/tutorials"}>
                <BlackButton aria-label={"Tutorials"}>LEARN MORE</BlackButton>
              </Link>
            </div>
          </Topics>
          <h2>F.A.Q.</h2>
          <FaqWrapper>
            {faqCategories.map((faqCategory, index) => (
              <div key={index}>
                <h3>{faqCategory}</h3>
                <FaqGrid>
                  {faqData.mainEntity
                    .filter((faqEntry) => faqEntry.about === faqCategory)
                    .map((faqEntry, index) => (
                      <ExpandableItem
                        key={index}
                        title={faqEntry.name}
                        expandableText={faqEntry.acceptedAnswer.text}
                      />
                    ))}
                </FaqGrid>
              </div>
            ))}
          </FaqWrapper>
        </Wrapper>
        {/*<SubmitWrapper>
          <Inner>
            <h2>Got any question?</h2>
            <Link to={"/support/submit-request"}>
              <Button aria-label={"Submit a request"} primary={true}>
                SUBMIT A REQUEST
              </Button>
            </Link>
            <p>Contact us and we’ll get back to you as soon as possible.</p>
          </Inner>
        </SubmitWrapper>*/}
      </Layout>
    </>
  );
};

export default Support;

const TitleWrapper = styled.div`
  max-width: 1600px;
  margin: 0 auto;
  padding: 0 var(--gutter);
  padding-top: 100px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  color: #fff;
`;

const ImgContainer = styled.div`
  height: 500px;
  width: 100%;
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 500px;
  background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.65) 0%,
      rgba(0, 0, 0, 0) 50%
    ),
    linear-gradient(180deg, rgba(0, 0, 0, 0) 50%, var(--color-dark-blue) 100%),
    rgba(19, 14, 102, 0.46);
`;

const Wrapper = styled.div`
  max-width: 1600px;
  margin: 0 auto;
  padding: 0 var(--gutter);
  text-align: center;

  h2 {
    margin: 64px auto;
  }
`;

const Topics = styled.div`
  width: 100%;
  display: grid;
  justify-content: center;
  grid-template-columns: 1fr 1fr;
  place-items: center;

  a {
    text-decoration: none;
  }

  div {
    margin-top: 32px;
    background: #fff;
    padding: 32px;
    width: 500px;
    height: 600px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-shadow: var(--shadow);
    border-radius: 20px;
  }

  @media (max-width: 1200px) {
    grid-template-columns: 1fr;
  }

  @media (max-width: 564px) {
    div {
      width: 100%;
    }
  }
`;

const BlackButton = styled(Button)`
  margin: 20px 0;
  color: #000;
  border-color: #000;
  &:hover {
    color: rgba(0, 0, 0, 0.7);
    border-color: rgba(0, 0, 0, 0.7);
  }
`;

const FaqWrapper = styled.div`
  width: 100%;
  text-align: left;
`;

const FaqGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 32px;
  @media (max-width: 1200px) {
    grid-template-columns: 1fr;
  }
`;

const SubmitWrapper = styled.div`
  width: 100%;
  background: #dedede;
  padding: 64px 0;

  a {
    text-decoration: none;
  }
`;

const Inner = styled.div`
  width: 100%;
  max-width: 1600px;
  margin: 0 auto;
  padding: 0 var(--gutter);
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;

  h2 {
    margin-top: 0;
  }
  p {
    margin-top: 36px;
  }

  @media (max-width: 480px) {
    a,
    button {
      width: 100%;
    }
  }
`;
